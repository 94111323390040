import { Component, OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DeactiveStatusComponent } from '../deactive-status/deactive-status.component';
import { DialogComponent } from '../dialog/dialog.component';
import { DatabaseService } from '../_services/DatabaseService';
import { SessionStorage } from '../_services/SessionService';

@Component({
  selector: 'app-categorypointmaster',
  templateUrl: './categorypointmaster.component.html',
  styleUrls: ['./categorypointmaster.component.scss']
})
export class CategorypointmasterComponent implements OnInit {
  loading: any;
  source: any = '';
  loading_page = false;
  loading_list = false;
  loader: any = false;
  locations: any = [];
  products:any = [];
  total_products:any = 0;
  last_page: number ;
  current_page = 1;
  search: any = '';
  searchData = true;
  isInvoiceDataExist = false;
  filter:any = {};
  filtering : any = false;
  savingData = false;
  productForm: any = {};
  image = new FormData();
  uploadurl:any='';
  addImageIcon=true;
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage,public dialog: DialogComponent, public alrt:MatDialog ) {}
  
  ngOnInit() {
    this.getCategoryPointMater('');
    this.productForm.image=[];
    this.category.profile_selected = 0;
    this.uploadurl = this.db.uploadUrl;
  }
  
  openDatePicker(picker : MatDatepicker<Date>)
  {
    picker.open();
  }
  
  redirect_previous() {
    this.current_page--;
    this.getCategoryPointMater('');
  }
  redirect_next() {
    if (this.current_page < this.last_page) { this.current_page++; }
    else { this.current_page = 1; }
    this.getCategoryPointMater('');
  }
  
  getCategoryPointMater(action) 
  {
    this.loading_list = true;
    this.filter.date = this.filter.date  ? this.db.pickerFormat(this.filter.date) : '';
    if( this.filter.date || this.filter.location_id )this.filtering = true;
   
    if(action=='refresh')
    {
      this.filter={};
    }

    console.log(this.filter);
    
    this.db.post_rqst({  'filter': this.filter , 'login':this.db.datauser}, 'karigar/showMasterUserCategory?page=' + this.current_page )
    .subscribe( d => {
      console.log(d);
      this.loading_list = false;


      this.current_page = d.category.current_page;
      this.last_page = d.category.last_page;
      this.total_products =d.category.total;
     
      this.products = d.category.data;

      this.productForm =  this.products;
      
      for(let i=0;i<this.products.length;i++)
      {
        if(this.products[i].status=="Active")
        {
          this.products[i].newsStatus=true;
        }
        else if(this.products[i].status=="Deactive")
        {
          this.products[i].newsStatus=false;
        }
      }
      
    });
  }
  
  category:any=[];
  
  catdata:any='';
  editcategoryPoint(id,index) {
    
    this.productForm = this.products.filter( x => x.id==id)[0];
    this.productForm.profile_selected = parseInt(this.productForm.profile);
    
  }
  toggle:any;
  save_category_point()
  {
    this.savingData = true; 
    
    if(this.products.id){
      this.productForm.edit_product_id = this.products.id;
    }
    this.productForm.image= this.selected_image ? this.selected_image : []
    this.db.post_rqst(  this.productForm , 'karigar/insertUserCategory')
    .subscribe( d => {
      console.log( d );
      this.savingData = false;
      this.image = new FormData();
      this.productForm = {};
      this.toggle = "false"
      
      if(d['status']== 'SUCCESS'){
      this.router.navigate(['category-point-master']);
      this.dialog.success('Successfully Added');
      this.getCategoryPointMater('');

      }

      else if(d['status']== 'UPDATED'){

        this.dialog.success('Successfully Updated');
      }
    
    });
  }
  
  selected_image :any = [];
  onUploadChange(data: any)
  {
    for(let i=0;i<data.target.files.length;i++)
    {
      let files = data.target.files[i];
      if (files) 
      {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.selected_image.push({"image":e.target.result});
          if(this.selected_image.length==0){
            this.addImageIcon=true;
          }
          else{
            this.addImageIcon=false;
          }
        }
        reader.readAsDataURL(files);
      }
      this.image.append(""+i,data.target.files[i],data.target.files[i].name);
    }
  }
  
  
  active:any='';
  ProductProfile(index,img_id)
  {
    this.active=index;
    this.productForm.profile_selected=img_id;
  }
  
  addProduct()
  {
    this.selected_image=[];
    this.productForm={};
    this.addImageIcon=true;
    
  }
  removeImage()
  {
    this.selected_image=[];
  }
  
  deleteCategoryPoint(id) {
    this.dialog.delete('Product').then((result) => {
      if(result) {
        this.db.post_rqst({id : id}, 'karigar/deleteUserCategory')
        .subscribe(d => {
          console.log(d);
          this.getCategoryPointMater('');
          this.dialog.successfully();
        });
      }
    });
  } 
  
  OnlyNumber(event: any){

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);
    
    if (event.keyCode != 8 && !pattern.test(inputChar)) { 
      
      event.preventDefault(); 
      
    }
  }
  
}